import * as React from "react";

import * as auth from "./../auth/auth-provider";

import { client } from "./../utils/api-client";
import { useAsync } from "./../utils/hooks";
import { useQuery } from "react-query";

import { FullPageSpinner } from "./../components/lib";

const fetchUserData = async () => {
  let user = null;

  const token = await auth.getToken();
  if (token) {
    user = auth.handleUserResponse({ jwt: token });
  }
  return user;
};

const AuthContext = React.createContext(undefined);
AuthContext.displayName = "AuthContext";

function AuthProvider(props) {
  const {
    data: user,
    status,
    error,
    isLoading,
    isIdle,
    isError,
    isSuccess,
    run,
    setData,
  } = useAsync();

  React.useEffect(() => {
    const userDataPromise = fetchUserData();
    run(userDataPromise);
  }, [run]);

  const login = React.useCallback(
    (form) =>
      auth
        .login(form)
        .then((user) => setData(user))
        .catch((err) => alert("Login nicht erfolgreich")),
    [setData]
  );

  const logout = React.useCallback(() => {
    auth.logout();
    setData(null);
  }, [setData]);

  const value = React.useMemo(
    () => ({ user, login, logout }),
    [login, logout, user]
  );

  if (isLoading || isIdle) {
    return <FullPageSpinner />;
  }

  if (isError) {
    return <FullPageSpinner />;
  }

  if (isSuccess) {
    return <AuthContext.Provider value={value} {...props} />;
  }

  throw new Error(`Unhandled Status ${status}`);
}

function useAuth() {
  const context = React.useContext(AuthContext);
  if (context === undefined) {
    throw new Error("useAuth must be used within AuthProvider");
  }
  return context;
}

function useClient() {
  const { user } = useAuth();
  const token = user?.token;
  return React.useCallback(
    (endpoint, config) => client(endpoint, { ...config, token }),
    [token]
  );
}

const _flatReqs = (reqs) => reqs.map((req) => req.id);

function useCanTakeShift() {
  const { user } = useAuth();
  const client = useClient();
  const { data: dbUser, isLoading } = useQuery(`user/${user.userId}`, client);
  const canTakeShift = React.useCallback(
    (shift) => {
      if (isLoading) return false;
      const userRequirements = dbUser.requirements;
      const { requirements: shiftRequirements } = shift;
      const flatUserReqs = _flatReqs(userRequirements);
      let ok = true;
      _flatReqs(shiftRequirements).forEach((sr) => {
        if (flatUserReqs.indexOf(sr) < 0) ok = false;
      });
      return ok;
    },
    [isLoading, dbUser]
  );
  return canTakeShift;
}

export { AuthProvider, useAuth, useClient, useCanTakeShift };
