import decodeJwt from "jwt-decode";

const localStorageKey = "vk_schedule_token";

const authBaseUrl = `${process.env.REACT_APP_AUTH_BASE_URL}/authentication`;

const getToken = async () => window.localStorage.getItem(localStorageKey);
const removeToken = () => window.localStorage.removeItem(localStorageKey);

const handleUserResponse = async ({ jwt }) => {
  await window.localStorage.setItem(localStorageKey, jwt);
  const { id: userId, role, username } = decodeJwt(jwt);
  return {
    userId,
    role,
    username,
    token: jwt,
  };
};

const login = ({ username, password }) =>
  authReq("jwt", { username, password })
    .then(handleUserResponse)
    .then(() => (window.location = "/"));
const logout = () => removeToken();

const authReq = async (endpoint: string, data: object) => {
  const basicAuthPayload = btoa(`${data.username}:${data.password}`);
  const config = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Basic ${basicAuthPayload}`,
    },
  };

  return window
    .fetch(`${authBaseUrl}/${endpoint}`, config)
    .then(async (response) => {
      const data = await response.json();
      if (response.ok) {
        return data;
      } else {
        return Promise.reject(data);
      }
    });
};

export {
  getToken,
  removeToken,
  login,
  logout,
  localStorageKey,
  handleUserResponse,
};
