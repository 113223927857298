import * as React from "react";

import { BrowserRouter as Router } from "react-router-dom";
import { ReactQueryConfigProvider } from "react-query";
import { AuthProvider } from "./auth-context";
import { StylesProvider } from "@material-ui/core/styles";
import NoSsr from "@material-ui/core/NoSsr";
import { ThemeProvider as EmotionThemeProvider } from "@emotion/react";
import {
  createTheme,
  ThemeProvider as MuiThemeProvider,
} from "@material-ui/core/styles";

const customTheme = createTheme({
  palette: {
    primary: {
      main: "#1a171b",
    },
    secondary: {
      main: "#a6c400",
    },
    error: {
      main: "#f44336",
    },
    warning: {
      main: "#ff9800",
    },
    info: {
      main: "#2196f3",
    },
    success: {
      main: "#4caf50",
    },
  },
});

const queryConfig = {
  queries: {
    useErrorBoundary: true,
    refetchOnWindowFocus: false,
    retry(failureCount, error) {
      if (error.status === 404) return false;
      else if (failureCount < 2) return true;
      else return false;
    },
  },
};

function AppProviders({ children }) {
  return (
    <ReactQueryConfigProvider config={queryConfig}>
      <Router>
        <StylesProvider injectFirst>
          <NoSsr>
            <MuiThemeProvider theme={customTheme}>
              <EmotionThemeProvider theme={customTheme}>
                <AuthProvider>{children}</AuthProvider>
              </EmotionThemeProvider>
            </MuiThemeProvider>
          </NoSsr>
        </StylesProvider>
      </Router>
    </ReactQueryConfigProvider>
  );
}

export { AppProviders };
