import { queryCache } from "react-query";
import * as auth from "./../auth/auth-provider";
import decodeJwt from "jwt-decode";
import { isPast, fromUnixTime } from 'date-fns'

import queryString from "query-string";

const apiBaseUrl = `${process.env.REACT_APP_API_BASE_URL}`;

const logout = async () => {
  // check if jwt was not already removed from localstorage
  if(await auth.getToken()) {
    queryCache.clear();
    auth.logout();
    window.location.assign(window.location);
  }
  return Promise.reject({ message: "Please re-authenticate" });
}

async function client(
  endpoint,
  {
    data,
    token,
    headers: customHeaders,
    query,
    transformResponse = async (response) => {
      return response.json();
    },
    ...customConfig
  } = {}
) {
  // check if token is expired before doing request
  if (isPast(fromUnixTime(decodeJwt(token).exp))) {
    return await logout();
  }
  const config = {
    method: data ? "POST" : "GET",
    body: data ? JSON.stringify(data) : undefined,
    headers: {
      Authorization: token ? `Bearer ${token}` : undefined,
      "Content-Type": data ? "application/json" : undefined,
      ...customHeaders,
    },
    ...customConfig,
  };
  const q = queryString.stringify(query) || "";
  return window
    .fetch(`${apiBaseUrl}/${endpoint}${q ? "?" + q : ""}`, config)
    .then(async (response) => {
      if (response.status === 401) {
        return logout();
      }
      if (response.status === 403) {
        (async () => {
          const message = await response.text();
          alert(`${response.statusText}: ${message}`);
        })();
        return Promise.reject({ message: response.statusText });
      }
      if (response.ok) {
        const data = await transformResponse(response);
        return data;
      } else {
        try {
          const data = await response.json();
          return Promise.reject(data);
        } catch (_e) {
          //return Promise.reject({ message: response.statusText });
          throw new Error(response.statusText);
        }
      }
    });
}

export { client };
