import DateFnsUtils from "@date-io/dayjs";
import styled from "@emotion/styled";
import MuiButton from "@material-ui/core/Button";
import MuiCheckbox from "@material-ui/core/Checkbox";
import MuiCircularProgress from "@material-ui/core/CircularProgress";
import MuiFormControlLabel from "@material-ui/core/FormControlLabel";
import MuiPaper from "@material-ui/core/Paper";
import MuiTextField from "@material-ui/core/TextField";
import {
  KeyboardDatePicker as MuiDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import dayjs from "dayjs";
import * as React from "react";
import { splitFormProps, useField } from "react-form";

const FullPageDiv = styled.div({
  height: "100vh",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
});

const FormGroup = styled.div({
  display: "flex",
  flexDirection: "column",
  marginBottom: "1rem",
});

const Paper = styled(MuiPaper)(() => ({
  padding: "2rem",
}));

const Input = React.forwardRef((props, ref) => {
  const [field, fieldOptions, rest] = splitFormProps(props);

  const {
    meta: { error: helperText },
    getInputProps,
  } = useField(field, fieldOptions);

  const error = helperText ? true : false;

  return (
    <MuiTextField
      {...getInputProps({ ref, ...rest, error, helperText })}
      style={{ width: "100%" }}
    />
  );
});

const DatePicker = ({ label, ...props }) => {
  const [field, fieldOptions, restProps] = splitFormProps(props);

  const { setValue, value } = useField(field, fieldOptions);

  const handleChange = (newDate) =>
    setValue(dayjs(newDate).format("YYYY-MM-DD"));

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <MuiDatePicker
        label={label}
        value={value}
        onChange={handleChange}
        format={"DD.MM.YYYY"}
        autoOk
        cancelLabel="Abbrechen"
        {...restProps}
      />
    </MuiPickersUtilsProvider>
  );
};

const Checkbox = ({ field, label, checked }) => {
  const { getInputProps, setValue, value } = useField(field, {
    defaultValue: checked,
  });

  const onChange = (e) => {
    setValue(e.target.checked);
  };

  return (
    <MuiFormControlLabel
      control={
        <MuiCheckbox {...getInputProps()} onChange={onChange} checked={value} />
      }
      label={label}
    />
  );
};

const Button = styled(MuiButton)({});

function FullPageSpinner() {
  return (
    <FullPageDiv>
      <MuiCircularProgress />
    </FullPageDiv>
  );
}

function FullPageErrorFallback({ error }) {
  return (
    <FullPageDiv role="alert">
      <p>Uh oh... Versuch mal die Seite neuzuladen...</p>
      <pre>{error.message}</pre>
    </FullPageDiv>
  );
}

function Spinner({ props }) {
  return <MuiCircularProgress {...props} />;
}

function ErrorMessage({ error, ...props }) {
  return (
    <div role="alert" {...props}>
      <span>Upsi, Fehler: </span>
      <pre>{error.message}</pre>
    </div>
  );
}

export {
  FullPageSpinner,
  FullPageDiv,
  Paper,
  Spinner,
  FormGroup,
  Input,
  Checkbox,
  Button,
  ErrorMessage,
  FullPageErrorFallback,
  DatePicker,
};
